<template>
    <div class="card">
        <div class="card-header border-0">
            <div class="card-title w-100 d-flex justify-content-between">
                <h1>Eğitmenler </h1>
                <a href="#"
                data-bs-toggle="modal"
                @click="educator = {}"
                id="marka_ekle_buton"
                data-bs-target="#kt_modal_add_egitmen" class="btn btn-primary">
                <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni Eğitmen</a>
            </div>
        </div>
        <div class="card-body pt-0 pb-5">
            <veri-tablo :datas="datas" :headers="tableHeader" :links="links" :metas="metas">
                 <template v-slot:cell-image="{ row: item }">
                    <img :src="item.image" style="width:100px;">
                </template>
                <template v-slot:cell-fullName="{ row: item }">
                    {{ item.fullName }}
                </template>
                <template v-slot:cell-explanation="{ row: item }">
                    {{ item.explanation }}                     
                </template>
                <template v-slot:cell-actions="{row:item}">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link rounded p-3 bg-light">
                            İşlemler<el-icon class="el-icon--right"><arrow-down /></el-icon>
                        </span>
                        <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-if="false">Görüntüle</el-dropdown-item>
                            <el-dropdown-item @click="educator = item">
                               <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_update_egitmen"  id="marka_update_buton" style="color:var(--el-text-color-regular);"> Güncelle </a>
                            </el-dropdown-item>
                            <el-dropdown-item @click="deleteItem(item)" >Sil</el-dropdown-item>
                        </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </veri-tablo>
        </div>
    </div>
    <empty-modal modalId="kt_modal_add_egitmen" ref="eklemodal__ref" modalTitle="Eğitmen Yarat"> 
        <template v-slot:ModalBody>
            <div class="row">
                <div class="col"> 
                    <div class="row">
                        <div class="col-md-8"> 
                            <div class="input-group mb-5">
                                <label for="" class="form-label">Eğitmen Adı - Soyadı</label>
                                <input type="text  w-100" v-model="educator.fullName" class="form-control w-100" placeholder="Eğitmen Adı - Soyadı" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                            <div class="input-group mb-5">     
                                <label for="" class="form-label">Eğitmen Hakkında</label>     
                                <input type="text" style="width:100%;" v-model="educator.explanation" class="form-control" placeholder="Eğitmen Hakkında" aria-label="Username" aria-describedby="basic-addon1"/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <el-upload
                                class="avatar-uploader"
                                :auto-upload="false"
                                :on-change="onImageChange"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                                <el-icon v-else class="avatar-uploader-icon d-flex justify-content-center align-items-center align-content-center"><Plus /></el-icon>
                            </el-upload>
                        </div>
                    </div>
                    
                </div>
                
            </div>

            
        </template>
        <template v-slot:ModalFooter>
            <a id="marka_ekle_buton"
                @click="save()"
                class="btn btn-primary"> 
                Kaydet
            </a>
        </template>
    </empty-modal>
    <empty-modal modalId="kt_modal_update_egitmen" ref="gucellememodal__ref" modalTitle="Eğitmen Güncelle"> 
        <template v-slot:ModalBody>
            <div class="row">
                <div class="col-md-12"> 
                    <div class="input-group mb-5">
                        <label for="" class="form-label">Eğitmen Adı - Soyadı </label>
                        <input type="text" v-model="educator.fullName" class="form-control w-100" placeholder="Eğitmen Adı - Soyadı" aria-label="Username" aria-describedby="basic-addon1"/>
                    </div>
                </div>
  
            </div>
            
            <div class="row">
                <div class="col-md-12"> 
                    <div class="input-group mb-1">       
                        <label for="" class="form-label">Eğitmen Hakkında</label>   
                    </div>
                </div>
            </div> 
            <div class="row">
                <div class="col-md-12"> 
                    <div class="input-group mb-5">       
                        <input type="text" v-model="educator.explanation" class="form-control" placeholder="Eğitmen Hakkında" aria-label="Username" aria-describedby="basic-addon1"/>
                    </div>
                </div>
            </div> 

        </template>
        <template v-slot:ModalFooter>
            <a 
            id="marka_ekle_buton"
            @click="update()"
            class="btn btn-primary"> 
                Kaydet
            </a>
            <button 
            @click="gucellememodal__ref.close()"
            id="marka_ekle_buton"
            class="btn btn-danger"> 
                Kapat
            </button>
        </template>
    </empty-modal>

</template>
<script>

import { useStore } from "vuex";
import { ArrowDown, Plus } from '@element-plus/icons-vue';
import { computed, onMounted ,reactive, ref } from "vue";
import store from '@/store';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { inject } from 'vue';


import  useCruds  from '@/composables/useCrud.js';


export default {
  components: {
    VeriTablo,
    EmptyModal,
    ArrowDown,
    Plus
    
  },
    setup() {
        const educator = ref({instructorId:0,fullName:'',explanation:''});
        const payload = reactive( {slug: "/instructors",data: educator})
        
        const eklemodal__ref = ref();
        const gucellememodal__ref = ref();

        const imageUrl = ref('');
        const { saveCrud, updateCrud, deleteCrud,loadCrud } = useCruds();

        const tableHeader = [
            {
                name: "Fotoğraf",
                key: "image",
            },
            {
                name: "Eğitmen Adı",
                key:'fullName'
            },
            {
                name: "Açıklama",
                key: "explanation",
            },
            {
                name: "İşlemler",
                key: "actions",
            },
        ];

        const store = useStore();
        const router = useRouter();

        const datas = ref({});
        const metas = ref({});
        const links = ref({});
  
       
        const save = () => {
            saveCrud(payload).then(
                function(value) {
                    datas.value.unshift(value.data);
                    eklemodal__ref.value.close();
                }
            );
        };
        const update = () => {
            console.log(educator);
            payload.slug = "/instructors/"+educator.value.instructorId;
            updateCrud(payload).then(
                function(value) { 
                    gucellememodal__ref.value.close();
                }
            );
        };
        const emitter = inject('emitter');

        const deleteItem = (item) => {
            payload.slug = "/instructors/"+item.instructorId;
            deleteCrud(payload).then(function(value){
                if(value){
                    for (let i = 0; i < datas.value.length; i++) {
                        if (datas.value[i] === item) {
                            datas.value.splice(i, 1);
                        }
                    }
                }
            });
            
        };


        function loadPicture(file) {
           
            let img = document.getElementById('img');
            var reader = new FileReader();
            reader.onload = function(e) {
                imageUrl.value = e.target.result;
            }
            reader.readAsDataURL(file);
        }   
        const onImageChange = (file) => {
            loadPicture(file.raw);
        };
        onMounted(() => {
            loadCrud('instructors').then(function(response){
                if(response.success){

                    datas.value = response.data;
                    links.value = response.links;
                    metas.value = response.meta;
                }
            });
             emitter.on('PageButtonClicked', (value) => {     
                if(router.currentRoute.value.path == "/egitmenler"){
                    loadCrud('/instructors',value).then(function(response){
                        if(response.success){
                            datas.value = response.data;
                            links.value = response.links;
                            metas.value = response.meta;
                        }
                    });
                }
            });

            
        });
       
        return {
            tableHeader,
            datas,
            educator,
            deleteItem,
            save,
            update,
            links,
            metas,
            eklemodal__ref,
            gucellememodal__ref,
            imageUrl,
            onImageChange
        }
    }
}


</script>
<style lang="css">
   .colorful-div{
        width:100px;
        height:24px;
        border-radius:8px;
        padding:5px;
        background:#fff;
        font-family: 'Comfortaa', cursive;
        cursor:pointer;
        color:#fff;
        margin:auto;
   }
   
   .badge{
       width:24px;
       height:24px;
       display:flex;
       justify-content:center;
       align-items:center;
       align-content:center;
   }

    .avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    }

</style>


<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>